import React from 'react'
import Logo from '../../assets/images/logo_black.svg'
import './UnderConstruction.scss'

export default function UnderConstruction() {
    return (
        <section className="text-center under-construction">
            <div>
                <img src={Logo} alt="Databaza.art" />
                <h3>
                    Databaza.art is under construction!
                </h3>
                {/* <h4>Thank you for your understanding!</h4> */}
            </div>
        </section>
    )
}
