import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/sass/App.scss'
import './i18n'
import Routes from './Routes'
import Header from './components/Header'
import Footer from './components/Footer'
import Alert from './components/Alert'
import { useTranslation } from 'react-i18next'
import './assets/icons/fontawesome-pro-5.11.2-web/css/all.min.css'
import LanguageContextProvider from './contexts/LanguageContext'
import AlertContextProvider from './contexts/AlertContext'
import AuthContextProvider from './contexts/AuthContext'
import UnderConstruction from './components/UnderConstruction/UnderConstruction'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Form, Col } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import useForm from 'react-hook-form'
import Api from './api/Api'
import BlackLogo from './assets/images/databaza_icon.png'
import Auth from './auth'
// reset git head

const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE,
    containerStyle: {
        paddingTop: 150,
        zIndex: 2000,
    },
}

function App() {
    const { t } = useTranslation()

    const globalContent = t('global', { returnObjects: true })
    const headerContent = t('header', { returnObjects: true })
    const footerContent = t('footer', { returnObjects: true })
    const newsletterContent = t('newsletterBox', { returnObjects: true })
    const { nav } = globalContent

    const [lightbox, setLightbox] = useState(true)
    const handleHideLightbox = () => {
        setLightbox(false)
    }

    const [uc, setUc] = useState(true)

    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (window.location.hostname === 'localhost') setUc(false)
    }, [])

    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [submitMessage, setSubmitMessage] = useState('')
    const { register, handleSubmit, errors, reset } = useForm()

    // let submitMessage

    const handleNewsletterSubmit = async data => {
        setLoading(true)
        const response = await Api.post('newsLetterRegister', data)
        setLoading(false)
        reset()
        if (!response || !response.data || response.data.confirmation === 'Fail') {
            setSubmitted(true)
            setSubmitMessage(newsletterContent.errorMessage)
            return
        }
        setSubmitMessage(newsletterContent.successMessage)
        setSubmitted(true)
    }

    useEffect(() => {
        if (submitted) {
            const timer = setTimeout(() => setLightbox(false), 2000)
            return () => clearTimeout(timer)
        }
    }, [submitted])

    return (
        <div className={`App ${modal ? 'modalOpen' : ''}`}>
            {uc ? (
                <UnderConstruction />
            ) : (
                <>
                    {lightbox && process.env.REACT_APP_ENV !== 'dev' && !Auth.isLoggedIn() && (
                        // newsletter
                        <>
                            <div className="lightbox-overlay" onClick={handleHideLightbox}></div>
                            <div className="lightbox-wrapper">
                                <div className="lightbox-content">
                                    <div>
                                        <span
                                            style={{ top: 15, right: 15 }}
                                            className="close-lightbox"
                                            onClick={handleHideLightbox}
                                        >
                                            <i className="fal fa-times"></i>
                                        </span>
                                    </div>
                                    {/* newsletter part */}
                                    <div style={{ marginBottom: '20px' }}>
                                        <img className="databaza-logo" src={BlackLogo} alt="Databaza.art" />
                                    </div>
                                    <div className="newsletter-subscribe" style={{ border: 'none', padding: 0 }}>
                                        <h5 className="newsletter-subscribe-title text-center">
                                            {newsletterContent.title}
                                        </h5>
                                        {submitted ? (
                                            <h6 className="text-center">{submitMessage}</h6>
                                        ) : (
                                            <Form onSubmit={handleSubmit(handleNewsletterSubmit)}>
                                                <Form.Row>
                                                    <Col sm="12">
                                                        <Form.Group>
                                                            <Form.Control
                                                                ref={register({
                                                                    required: 'Email cannot be empty',
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: 'Invalid email address',
                                                                    },
                                                                })}
                                                                type="email"
                                                                name="email"
                                                                placeholder="E-mail"
                                                            />
                                                            <div className="form-label">
                                                                <i class="fal fa-envelope"></i>
                                                                <span className="required-field"></span>
                                                            </div>
                                                            {errors && errors.email && errors.email.message && (
                                                                <span className="required-field-newsletter">
                                                                    {errors.email.message}
                                                                </span>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm="12">
                                                        <Form.Group className="text-center">
                                                            <button
                                                                disabled={loading}
                                                                className="btn btn-dark btn-lg btn-block text-white"
                                                                type="submit"
                                                            >
                                                                {loading ? (
                                                                    <Spinner size="sm" animation="border" />
                                                                ) : (
                                                                    newsletterContent.buttonTitle
                                                                )}
                                                            </button>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <LanguageContextProvider>
                        <AuthContextProvider>
                            <AlertContextProvider>
                                <AlertProvider template={AlertTemplate} {...options}>
                                    <Header nav={nav} headerContent={headerContent} setModal={setModal} />
                                    <Routes />
                                    <Footer nav={nav} footerContent={footerContent} />
                                    <Alert />
                                </AlertProvider>
                            </AlertContextProvider>
                        </AuthContextProvider>
                    </LanguageContextProvider>
                </>
            )}
        </div>
    )
}

export default App
